import React from "react"
import PortfolioItem from "../../templates/portfolioItem"

const Projects = props => {
  const items = props.data.edges.sort((a, b) => (a.node.frontmatter.id > b.node.frontmatter.id) ? 1 : -1);

  return (
    <section className="projects-wrapper">
      <h3>{props.title}</h3>
      {items.map((data, i) => (
        <PortfolioItem
          key={data.node.frontmatter.id}
          title={data.node.frontmatter.title}
          content={data.node.html}
          url={data.node.frontmatter.url}
          demo={data.node.frontmatter.demo}
          source={data.node.frontmatter.source}
          image={data.node.frontmatter.image}
          modClass={props.modClass}
        />
      ))}
    </section>
  )
}

export default Projects
