import React from "react"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import Img from "gatsby-image"

import Reveal from "react-reveal/Reveal"

import GitHub from "../media/assets/socials/github.svg"
import Link from "../media/assets/icons/link.svg"

const PortfolioItem = props => {
  return (
    <Reveal duration={750}>
      <section className="portfolio-item-container">
        <div className="item">
          <h4>{props.title}</h4>
          <div dangerouslySetInnerHTML={{ __html: props.content }} />
          <div className="item-buttons">
            {props.source !== null
              ? [
                  <OutboundLink
                    key="source"
                    className="link mod-view-source"
                    href={props.source}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button>
                      <img alt="view-source" src={GitHub} />
                      View source
                    </button>
                  </OutboundLink>,
                ]
              : null}
            {props.url !== null
              ? [
                  <OutboundLink
                    key="url"
                    className="link mod-visit"
                    href={props.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button>
                      <img alt="view-source" src={Link} />
                      Visit
                    </button>
                  </OutboundLink>,
                ]
              : null}
            {props.demo && (
              <OutboundLink
                key="url"
                className="link mod-visit"
                href={props.demo}
                target="_blank"
                rel="noopener noreferrer"
              >
                <button>
                  <img alt="view-source" src={Link} />
                  Demo
                </button>
              </OutboundLink>
            )}
          </div>
        </div>
        <OutboundLink
          className={`project-image ${props.modClass}`}
          href={props.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Img fadeIn={true} fluid={props.image.childImageSharp.fluid} />
        </OutboundLink>
      </section>
    </Reveal>
  )
}
export default PortfolioItem
