import React, { Fragment } from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout/layout"
import Projects from "../components/portfolio/projects"

const PortfolioPage = props => {
  return (
    <Fragment>
      <Layout>
        <Img
          fadeIn={true}
          className="main-image-wrapper"
          fluid={props.data.main.childImageSharp.fluid}
        />
        <h1>Portfolio</h1>
        <h2>recent projects</h2>
        <p>
          I specialize in crafting state-of-the-art web and mobile applications
          that deliver exceptional user experiences with optimal performance. My
          expertise lies in leveraging modern software stacks and frameworks to
          create responsive and scalable applications. Alongside my personal
          projects, I have actively contributed to various industry
          applications. Feel free to explore my work and reach out if you are a
          business in need of establishing a compelling web presence!
        </p>
        <div>
          <Link to="/contact">
            <button>Get in Touch</button>
          </Link>
        </div>
        <Projects
          title="Industry projects"
          data={props.data.industryProjects}
          modClass={"mod-industry-project"}
        />
        <Projects
          title="Personal projects"
          data={props.data.projects}
          modClass={""}
        />
      </Layout>
    </Fragment>
  )
}

export default PortfolioPage

export const portfolioImage = graphql`
  fragment portfolioImage on File {
    childImageSharp {
      fluid(maxWidth: 900) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const data = graphql`
  query {
    main: file(relativePath: { eq: "media/main/portfolio.jpg" }) {
      ...portfolioImage
    }

    projects: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/projects/" } }
      sort: { fields: [frontmatter___id], order: ASC }
    ) {
      edges {
        node {
          html
          frontmatter {
            id
            title
            url
            source
            image {
              childImageSharp {
                fluid(maxWidth: 900) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }

    industryProjects: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/work-projects/" } }
    ) {
      edges {
        node {
          html
          frontmatter {
            id
            title
            url
            demo
            source
            image {
              childImageSharp {
                fluid(maxWidth: 900) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
